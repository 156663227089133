import * as XLSX from 'xlsx';

import { AllocationKey } from 'backend/types';
import { CoOwner, ValidationErrorCode } from 'backend/types';

export enum RowType {
  ALLOCATION_KEY = 'CLE',
  CO_OWNER = 'COP',
}

export const coOwnerImportFields = [
  'coOwnerId',
  'civility',
  'name',
  'address',
  'postalCode',
  'city',
  'country',
  'phoneNumber',
  'lots',
  'email',
] as [
  'coOwnerId',
  'civility',
  'name',
  'address',
  'postalCode',
  'city',
  'country',
  'phoneNumber',
  'lots',
  'email',
];

export interface ParsedData {
  allocationKeys: Record<string, AllocationKey>;
  coOwners: Record<string, Pick<CoOwner, typeof coOwnerImportFields[number]>>;
  errors: {
    rowType: RowType;
    row: number;
    field: string;
    code: ValidationErrorCode;
  }[];
}

export const parseFile = (
  file: File,
  parsingFunction: (data: (string | number | undefined)[][]) => ParsedData,
): Promise<ParsedData> =>
  new Promise<ParsedData>((resolve) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      const [wsname] = wb.SheetNames;
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* Process data */
      resolve(parsingFunction(data as (string | number)[][]));
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
