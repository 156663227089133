import * as yup from 'yup';
import { A, O } from 'ts-toolbelt';

import { coOwnerSchema, coOwnerPublicInfosSchema } from '../schemas/coOwner';

export enum CoOwnerEventType {
  PHYSICALLY_JOINED = 'PHYSICALLY_JOINED',
  PHYSICALLY_LEFT = 'PHYSICALLY_LEFT',
  REMOTELY_JOINED = 'REMOTELY_JOINED',
  REMOTELY_LEFT = 'REMOTELY_LEFT',
  ANNOUNCED_REPRESENTED_BY = 'ANNOUNCED_REPRESENTED_BY',
}

export enum NotificationStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum NotificationStatusReason {
  MISSING_EMAIL = 'MISSING_EMAIL',
  MISSING_PHONE_NUMBER = 'MISSING_PHONE_NUMBER',
}

export type CoOwner = A.Compute<
  O.Undefinable<yup.Asserts<typeof coOwnerSchema>, 'votes'>
>;

export type CoOwnerPublicInfos = A.Compute<
  yup.Asserts<typeof coOwnerPublicInfosSchema>
>;
export interface CoOwnersTantiemesAndCount {
  totalTantiemes: number;
  representedTantiemes: number;
  totalCoOwnersCount: number;
  representedCoOwnersCount: number;
}
